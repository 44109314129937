import React from 'react';

import * as image from '../assets'

const Logo = () => {

  return (
    <header>
      <img src={image.LOGO} alt='Logo devari shop'/>
    </header> 
  );
}

export default Logo;