import React from 'react';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import {Spinner} from 'reactstrap';

import Logo from './Logo';
import AppBar from './AppBar';
import { toReal } from '../utils';
import * as image from '../assets'

const checkoutForm = ({
    tokenPrice,
    tokenTitle,
    hasToken,
    checkout,
    step,
    loading,
    setModal,
    handleCards,
    handleCheckout,
    handleAccount,
    submitAccount,
    submitCheckout
}) => {
    const _CURRENT_YEAR = new Date().getFullYear();
    const CURRENT_PRICE = tokenPrice/checkout.length;
    return (
        <div id="wrapper">
            <Logo />
            <AppBar step={step} />
            {step == 0 ? <form autoComplete='off' onSubmit={submitAccount} className="box">
                <p>Dados Pessoais</p>
                <input type='text' placeholder="Nome" onChange={({target}) => handleAccount('first_name', target.value)} />
                <input type='text' placeholder="Sobrenome" onChange={({target}) => handleAccount('last_name', target.value)}/>
                <input type='text' placeholder="E-mail" type="email" onChange={({target}) => handleAccount('email', target.value)}/>
                <InputMask placeholder="Celular com DDD" mask="(99)99999-9999" type='tel' onChange={({target}) => handleAccount('phone', target.value)}/>
                {/* <div id='input-form'>
                    <label>Data de nascimento</label> <br />
                    <input type='date' onChange={({target}) => handleAccount('birthdate', target.value)}/>
                </div> */}
                <InputMask placeholder="CPF" mask="999.999.999-99" type="tel" onChange={({target}) => handleAccount('taxpayer', target.value)}/>
                <button className="confirm-button">
                    Próximo
                </button>
            </form>
            :
            step == 1 ? <form autoComplete='off' onSubmit={submitCheckout} className="">
                <div className="cards-logo">
                    <img src={image.ELO_LOGO}/>
                    <img src={image.HIPER_CARD_LOGO}/>
                    <img src={image.HIPER_LOGO}/>
                    <img src={image.JBC_LOGO}/>
                    <img src={image.VISA_LOGO} />
                </div>
                <div id="bottom-cards" className="cards-logo">
                    <img src={image.MASTER_LOGO} />
                    <img src={image.BANES_LOGO} />
                    <img src={image.AURA_LOGO} />
                    <img src={image.AMERICAN_LOGO} />
                </div>
                {checkout.map((item, index) => (
                    <div key={index.toString()} className="box">
                        {index > 0 && <div className="remove-card">
                            <img src={image.MINUS_ICON} onClick={() => handleCards(false, index)}/>
                            <span>remover cartão</span>
                        </div>}

                        {hasToken ?
                        <>
                            {/* <div style={{wordWrap: 'break-word'}}>
                                <p><strong>Pagamento por: {tokenTitle}</strong></p>
                            </div>
                            <div className="row-input">
                                <div id=''>
                                    <label><strong>Valor: {toReal(item.amount)}</strong></label>
                                </div>
                            </div> */}
                        </>
                        :
                        <CurrencyInput prefix='R$ '
                        onChangeEvent={(event, mask, value) => {
                            handleCheckout('amount', value, index)
                        }} 
                        value={item.amount}
                        /> }
                        <input 
                        placeholder="Nome do Titular" 
                        value={item.holder_name} type='text' 
                        onChange={({target}) => handleCheckout('holder_name', target.value, index)}/>
                        <input type='number' 
                        value={item.number} placeholder="Numero do Cartão" 
                        onChange={({target}) => handleCheckout('number', target.value, index)}/>
                        <div className="row-input">
                            <div className="customSelectContainer">
                                <select style={{marginRight: 10}} className="customSelect" onChange={({target}) => handleCheckout('exp_month', target.value, index)}>
                                    <option selected disabled hidden>MÊS</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                    <option>11</option>
                                    <option>12</option>
                                </select>
                                <select className="customSelect"
                                onChange={({target}) => handleCheckout('exp_year', target.value, index)}>
                                    <option selected disabled hidden>ANO</option>
                                    {Array.from(Array(15), (_, index) => <option value={_CURRENT_YEAR + index}>{_CURRENT_YEAR + index}</option>)}
                                </select>
                            </div>
                            <div className="cvvInput">
                                <InputMask type="number" placeholder="Cód. segurança" value={item.cvc} maxLength={4} onChange={({target}) => handleCheckout('cvc', target.value, index)}/>
                                <img onClick={() => setModal(true)} src={image.INFOR} id="infor-icon"/>
                            </div>
                        </div>
                        
                        <select className="customSelect"
                        onChange={({target}) => handleCheckout('installment_number', target.value, index)}>
                            <option value={1}>{`1x de R$ ${toReal(CURRENT_PRICE)}`}</option>
                            <option value={2}>{`2x de R$ ${toReal((CURRENT_PRICE/2))}`}</option>
                            <option value={3}>{`3x de R$ ${toReal((CURRENT_PRICE/3))}`}</option>
                            <option value={4}>{`4x de R$ ${toReal((CURRENT_PRICE/4))}`}</option>
                            <option value={5}>{`5x de R$ ${toReal((CURRENT_PRICE/5))}`}</option>
                            <option value={6}>{`6x de R$ ${toReal((CURRENT_PRICE/6))}`}</option>
                            <option value={7}>{`7x de R$ ${toReal((CURRENT_PRICE/7))}`}</option>
                            <option value={8}>{`8x de R$ ${toReal((CURRENT_PRICE/8))}`}</option>
                            <option value={9}>{`9x de R$ ${toReal((CURRENT_PRICE/9))}`}</option>
                            <option value={10}>{`10x de R$ ${toReal((CURRENT_PRICE/10))}`}</option>
                        </select>
                    </div>
                ))}
                {checkout.length < 2 && <div className="add-card">
                    <img src={image.PLUS_ICON} onClick={() => handleCards(true, 0)} />
                    <span className="">
                        adicionar cartão
                    </span>
                </div>}
                {loading ? 
                <div id="loading">
                    <Spinner color="info" />
                </div> :
                <div className="confirmButtonContainer">
                    <button className="confirm-button">
                        Finalizar
                    </button> 
                </div>
                }
            </form> :
            <div id="success-container">
                <h4>Pagamento realizado!</h4>
                <img src={image.SUCCESS} />
            </div>}
        </div>
    );
}

export default checkoutForm;