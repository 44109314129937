import React from 'react';
import InputMask from 'react-input-mask';
import {Spinner} from 'reactstrap';
import * as image from '../assets'

const sellerCheckoutForm = ({
    step,
    account,
    company,
    loading,
    brand,
    setType,
    listMCC,
    setModal,
    handleCheckout,
    handleAccount,
    handleCompany,
    submitAccount,
    submitAddress,
    submitCheckout,
    submitCompany
}) => {

    return (
        <div id="wrapper">
            {step == 0 ? <form autoComplete='off' onSubmit={submitAccount} className="box">
                <p>Dados Pessoais</p>
                <div id='input-form'>
                    <label>Nome</label> <br/>
                    <input type='text' value={account.first_name} placeholder="José" onChange={({target}) => handleAccount('first_name', target.value)} />
                </div>
                <div id='input-form'>
                    <label>Sobrenome</label> <br/>
                    <input type='text' value={account.last_name} placeholder="Raimundo" onChange={({target}) => handleAccount('last_name', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>E-mail</label> <br />
                    <input type='text' value={account.email} placeholder="jose@email.com" onChange={({target}) => handleAccount('email', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Telefone</label> <br/>
                    <InputMask placeholder="(99)99999-9999" value={account.phone_number} mask="(99)99999-9999" type='text' onChange={({target}) => handleAccount('phone_number', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Data de nascimento</label> <br />
                    <input type='date' value={account.birthdate} onChange={({target}) => handleAccount('birthdate', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>CPF</label> <br/>
                    <InputMask autocomplete="off" value={account.taxpayer_id} placeholder="999.999.999-99" mask="999.999.999-99" type='text' onChange={({target}) => handleAccount('taxpayer_id', target.value)}/>
                </div>
                <div id='input-form' className='select-container'>
                    <label>Tipo de vendedor</label>
                    <select onChange={({target}) => setType(target.value)}>
                        <option defaultChecked>PJ</option>
                        <option>CNPJ</option>
                    </select>
                </div>
                <div id="input-form" className="file-container">
                    <label for='photo-input'>Foto identidade <svg id="photo-icon" width="16" height="16" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M15 3H7c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM6 5H2V4h4v1zm4.5 7C8.56 12 7 10.44 7 8.5S8.56 5 10.5 5 14 6.56 14 8.5 12.44 12 10.5 12zM13 8.5c0 1.38-1.13 2.5-2.5 2.5S8 9.87 8 8.5 9.13 6 10.5 6 13 7.13 13 8.5z"></path></svg></label>
                    <input type='file' id="photo-input" onChange={({target}) => handleAccount('photo', target.files[0])}/>
                    {account.photo && <span id="file-name">{account.photo.name}</span>}
                </div>
                <button className="confirm-button">
                    Próximo
                </button>
            </form>
            :
            step == 1 ? <form autoComplete='off' onSubmit={submitAddress} className="box">
                <p>Dados Pessoais</p>
                <div id='input-form' className='select-container'>
                    <label>Tipo de negócio</label>
                    <select id='select-mcc' onChange={({target}) => handleAccount('mcc', target.value)}>
                        <option defaultChecked></option>
                        {listMCC.map(item => item.description && <option key={item.id} value={item.id}>{item.description}</option>)}
                    </select>
                </div>
                <div id='input-form'>
                    <label>Endereço</label> <br/>
                    <input value={account.line1} placeholder="Rua ABC" type='text' onChange={({target}) => handleAccount('line1', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Numero</label> <br/>
                    <input value={account.line3} placeholder="1456" type='text' onChange={({target}) => handleAccount('line3', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Cidade</label> <br/>
                    <input value={account.city} autocomplete="off" placeholder="Fortaleza" type='text' onChange={({target}) => handleAccount('city', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Estado</label> <br/>
                    <input placeholder="Ceára" value={account.state} type='text' onChange={({target}) => handleAccount('state', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>CEP</label> <br/>
                    <InputMask autocomplete="off" value={account.postal_code} placeholder="60.504-113" mask="99.999-999" type='text' onChange={({target}) => handleAccount('postal_code', target.value)}/>
                </div>
                {loading ? 
                <Spinner id="loading" color="info" /> :
                <button className="confirm-button">
                    Próximo
                </button> }
            </form> :
            step == 2 ? <form autoComplete='off' onSubmit={submitCompany} className="box">
                <p>Dados da Empresa</p>
                <div id='input-form'>
                    <label>Endereço</label> <br/>
                    <input placeholder="Rua ABC" value={company.business_address.line1} type='text' onChange={({target}) => handleCompany('line1', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Numero</label> <br/>
                    <input placeholder="1456" type='text' value={company.business_address.line3} onChange={({target}) => handleCompany('line3', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Cidade</label> <br/>
                    <input placeholder="Fortaleza" type='text' value={company.business_address.city} onChange={({target}) => handleCompany('city', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Estado</label> <br/>
                    <input placeholder="Ceára" type='text' value={company.business_address.state} onChange={({target}) => handleCompany('state', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Data de criação</label> <br />
                    <input type='date' onChange={({target}) => handleCompany('business_opening_date', target.valueAsDate)}/>
                </div>
                <div id='input-form'>
                    <label>CEP</label> <br/>
                    <InputMask placeholder="60.504-113" value={company.business_address.postal_code} mask="99.999-999" type='text' onChange={({target}) => handleCompany('postal_code', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>CNPJ</label> <br/>
                    <InputMask placeholder="00.000.000/0000-00" value={company.ein} mask="99.999.999/9999-99" type='text' onChange={({target}) => handleCompany('ein', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Nome da Empresa</label> <br/>
                    <input placeholder="Ceára" type='text' value={company.business_name} onChange={({target}) => handleCompany('business_name', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Email</label> <br/>
                    <input placeholder="Ceára" type='text' value={company.business_email} onChange={({target}) => handleCompany('business_email', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Telefone</label> <br/>
                    <InputMask placeholder="(99)99999-9999" value={company.business_phone} mask="(99)99999-9999" type='text' onChange={({target}) => handleCompany('business_phone', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Site</label> <br/>
                    <input placeholder="Ceára" type='text' value={company.business_website} onChange={({target}) => handleCompany('business_website', target.value)}/>
                </div>
                <div id='input-form'>
                    <label>Nome Fantasia</label> <br/>
                    <input placeholder="Ceára" type='text' value={company.statement_descriptor} onChange={({target}) => handleCompany('statement_descriptor', target.value)}/>
                </div>
                {loading ? 
                <Spinner id="loading" color="info" /> :
                <button className="confirm-button">
                    Próximo
                </button> }
            </form> :
            step == 3 ? <form autoComplete='off' onSubmit={submitCheckout} className="box">
                <p>Cartões aceitos</p>
                <div className="cards-logo">
                    <img src={image.ELO_LOGO}/>
                    <img src={image.HIPER_CARD_LOGO}/>
                    <img src={image.HIPER_LOGO}/>
                    <img src={image.JBC_LOGO}/>
                    <img src={image.VISA_LOGO} />
                </div>
                <div id="bottom-cards" className="cards-logo">
                    <img src={image.MASTER_LOGO} />
                    <img src={image.BANES_LOGO} />
                    <img src={image.AURA_LOGO} />
                    <img src={image.AMERICAN_LOGO} />
                </div>
                <div className="form-container">
                    <div id='input-form'>
                        <label>Nome do titular</label> <br />
                        <input placeholder="JOSÉ R A FARIAS" type='text' onChange={({target}) => handleCheckout('holder_name', target.value)}/>
                    </div>
                    <div className="row-input">
                        <div id="">
                            <label>Validade:</label> <br />
                            <InputMask placeholder="99/99" mask="99/99" onChange={({target}) => handleCheckout('exp_valid', target.value)}/>
                        </div>
                        <div id="">
                            <label>CVV:</label> <img onClick={() => setModal(true)} src={image.INFOR} id="infor-icon"/> <br />
                            <InputMask placeholder="156" maxLength={4} onChange={({target}) => handleCheckout('cvc', target.value)}/>
                        </div>
                    </div>
                    <div id='input-form'>
                        <label>Numero do cartão</label> <br/>
                        <input id="input-brand" type='text' placeholder="12345678912345" onChange={({target}) => handleCheckout('number', target.value)}/>
                        <img 
                        id="card-brand" 
                        src={ 
                            brand == "elo" ? image.ELO_LOGO :
                            brand == "hipercard" ? image.HIPER_CARD_LOGO :
                            brand == "amex" ? image.AMERICAN_LOGO :
                            brand == "mastercard" ? image.MASTER_LOGO :
                            brand == "visa" ? image.VISA_LOGO :
                            brand == "jcb" ? image.JBC_LOGO :
                            brand == "aura" ? image.AURA_LOGO :
                            image.CARD
                        }
                        />
                    </div>
                    <div className="row-input">
                        <div id=''>
                            <label>Valor</label> <br/>
                            <input type='text' placeholder="200" onChange={({target}) => handleCheckout('amount', target.value)}/>
                        </div>
                    </div>
                    <div id='input-form'>
                        <label>Parcelas </label> {" "}
                        <select
                        onChange={({target}) => handleCheckout('installment_number', target.value)}>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                        </select>
                    </div>
                </div>
                {loading ? 
                <Spinner id="loading" color="info" /> :
                <button className="confirm-button">
                    Finalizar
                </button> }
            </form> :
            <div id="success-container">
                <h4>Pagamento realizado!</h4>
                <img src={image.SUCCESS} />
            </div>}
        </div>
    );
}

export default sellerCheckoutForm;