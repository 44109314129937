import React from 'react';

import * as image from '../assets';

const sellerAppBar = ({step}) => (
    <div id="appbar">
        <div className="app-item app-item-on">
            <p>Criar usuário</p>
            <div className="line line-on">
                <img alt='circle' src={ step <= 1 ? image.CIRCLE : image.CIRCLE_OK}/>
            </div>
        </div>
        <div className={`app-item ${ step <= 1 ? 'app-item-off' : 'app-item-on'}`}>
            <p>Pagamento</p>
            <div className={`line ${ step <= 1 ? 'line-off' : 'line-on'} `}>
                {step === 3 ? 
                <img alt='circle' src={image.CIRCLE_OK} /> :
                <img alt='circle' src={image.CIRCLE} /> }
            </div>
        </div>
    </div>
);

export default sellerAppBar;