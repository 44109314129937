import React, { useEffect, useState } from 'react';
import { 
    Modal, ModalHeader, 
   ModalBody, ModalFooter, Alert
} from 'reactstrap';
import queryString from 'query-string'; 

import {
    validAccount, validCheckout, GENERATE_TOKEN_URL,
    APP_ID, APP_TOKEN, PAYMENT_URL, checkCardBrand
} from '../utils';
import AppBar from '../components/AppBar'
import CheckoutForm from '../components/checkoutForm';
import * as image from '../assets'
import axios from 'axios';

const CustomerCheckout = (props) => {
    
    const [alert, setAlert] = useState("");
    const [step, setStep] = useState(0);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasToken, setHasToken] = useState(false);
    const [tokenPrice, setTokenPrice] = useState(0);
    const [tokenTitle, setTokenTitle] = useState('');
    
    const [account, setAccount] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        // birthdate: "",
        taxpayer: "",
        description: "Pagamento online com o cartão pela devari pay"
    })

    const [checkout, setCheckout] = useState([{
        holder_name: "",
        exp_month: "",
        exp_year: "",
        number: "",
        cvc: "",
        amount: 0,
        installment_number: "1",
        brand: ""
    }])

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get("token");
        if(token) {
            setHasToken(true);
            loadProductInfor(token);
        }
        
    }, [])

    const loadProductInfor = async (token) => {
        try {
            const response = await axios.get(`${GENERATE_TOKEN_URL}/${token}`, {
                headers: {'Authorization': `Token ${APP_TOKEN}`}
            });
            const { name, value } = response.data;
            const amount = Number(value);
            setTokenPrice(amount);
            setTokenTitle(name);
            handleCheckout('amount', amount, 0);

        } catch (error) {
            console.log(error);
        }
        
    }

    const handleCards = (add=true, index) => {
        //copy current state from checkout
        let _currentCheckout = JSON.parse(JSON.stringify(checkout));

        if(add) {
            // calc new value for card
            let new_price = Number((tokenPrice / (checkout.length + 1)).toFixed(2));
            //fix new amout by card
            _currentCheckout.map(item => item.amount = new_price);

            let newCheckout = {
                holder_name: "",
                exp_month: "",
                exp_year: "",
                number: "",
                cvc: "",
                amount: new_price,
                installment_number: "1",
                description: "Pagamento online com o cartão pela devari pay",
                brand: ""
            }

            setCheckout([..._currentCheckout, newCheckout]);
        } else {
            // calc new value for card
            let new_price = Number((tokenPrice / (checkout.length - 1)).toFixed(2));
            //fix new amout by card
            _currentCheckout.map(item => item.amount = new_price);

            let newArray = _currentCheckout.filter((item, i) => i !== index);
            setCheckout(newArray);
        }
    }

    const handleAccount = (key, value) => {
        let newAccout;
        if (key === 'phone' || key === 'taxpayer') {
            newAccout = {...account, [key]: value.replace(/[^0-9]+/g, "")}
        } else {
            newAccout = {...account, [key]: value}
        }

        setAccount(newAccout);
    }
    
    const handleCheckout = (key, value, index) => {
        if(key === "exp_month") {
            const _month = value < 10 ? "0"+value : value.toString();
            let newCheckout = {
                ...checkout[index],
                exp_month: _month
            }
            let newArray = checkout.slice(0); //copying array
            newArray[index] = newCheckout;
            setCheckout(newArray);
            
        } else if (key === "exp_year") {
            let newCheckout = {
                ...checkout[index],
                exp_year: value.slice(-2)
            }
            let newArray = checkout.slice(0); //copying array
            newArray[index] = newCheckout;
            setCheckout(newArray)
        } else if (key === "number") {
            let check = checkCardBrand(value);
            let newCheckout;
            if (check) 
                newCheckout = {...checkout[index], [key]: value, brand: check.name}
            else 
                newCheckout = {...checkout[index], [key]: value, brand: ""}
            
            let newArray = checkout.slice(0); //copying array
            newArray[index] = newCheckout;
            setCheckout(newArray)

        } else if (key === "amount") {
            let newCheckout = {...checkout[index], [key]: value }
            let newArray = checkout.slice(0); //copying array
            newArray[index] = newCheckout;
            setCheckout(newArray)
        } else {
            let newCheckout = {...checkout[index], [key]: value}
            let newArray = checkout.slice(0); //copying array
            newArray[index] = newCheckout;
            setCheckout(newArray)
        }
    }

    const submitAccount = (form) => {
        form.preventDefault();

        let valid = validAccount(account, false);
        if(!valid.status) {
            setAlert(valid.message);
            return ;
        } else setStep(1)
    }

    const submitCheckout = (form) => {
        form.preventDefault();
        
        let check;
        let valid = checkout.every((item, index) => {
            check = validCheckout(item);
            if(!check.status) setAlert(`${check.message} no cartão ${index+1}`);
            return check.status
        });

        if(!valid) return ;

        setLoading(true);
        //converting real to cents by rules on gateway
        let _checkoutPayload = JSON.parse(JSON.stringify(checkout));
        _checkoutPayload.map(item => { return { ...item, amount: item.amount *= 100}});
        
        axios.post(`${PAYMENT_URL}`, {
            ...account,
            cards: _checkoutPayload,
            app_code: APP_ID
        }, {
            headers: {'Authorization': `Token ${APP_TOKEN}`}
        })
        .then(res => {
            //pagamento realizado com sucesso
            setLoading(false);
            setStep(2);
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
            setAlert("Não foi possível realizar o pagamento. Verifique suas informações ou entre em contato conosco contato@devari.com.br")
            //algo errado.
        })

    }
    

    return (
        <>
        <Alert fade={true}
        id="alert-message"
        color="danger" isOpen={alert.length} toggle={() => setAlert("")}>
        {alert}
        </Alert>
        
        <CheckoutForm 
        hasToken={hasToken}
        tokenPrice={tokenPrice}
        tokenTitle={tokenTitle}
        checkout={checkout}
        loading={loading}
        step={step}
        setModal={setModal}
        handleAccount={handleAccount}
        handleCheckout={handleCheckout}
        handleCards={handleCards}
        submitAccount={submitAccount}
        submitCheckout={submitCheckout}
        />
        <Modal isOpen={modal} toggle={()=>setModal(false)}>
            <ModalHeader className="justify-content-center">Código de Segurança</ModalHeader>
            <ModalBody className="text-center">
            <p>Digite o código de segurança (CVV) de 3 ou 4 dígitos presente no verso do cartão.</p>
            <img src={image.CARD} />
            </ModalBody>
            <ModalFooter className="justify-content-center">
            <button className="confirm-button" color="primary" onClick={() => setModal(false)}>Continuar</button>
            </ModalFooter>
        </Modal>
        </>
    );
}

export default CustomerCheckout;