import React, {useState, useEffect} from 'react'
import { 
    Alert, Modal, ModalHeader, 
    ModalBody, ModalFooter
 } from 'reactstrap';
import axios from 'axios';
import { 
    APP_ID, validAddress, validAccount, API_ZOOP_BASE,
    AUTH_ZOOP_KEY, API_ZOOP_URL, validCheckout, validCompany, 
    checkCardBrand, APP_TOKEN, PAYMENT_URL
} from '../utils'
import * as image from '../assets'
import SellerAppBar from '../components/sellerAppBar'
import SellerCheckoutForm from '../components/sellerCheckouForm';

const SellerCheckout = () => {
    const [alert, setAlert] = useState("")
    const [typeSeller, setTypeSeller] = useState("PJ");
    const [listMCC, setListMCC] = useState([]);
    const [step, setStep] = useState(0)
    const [brand, setBrand] = useState("")
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [account, setAccount] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        birthdate: "",
        taxpayer_id: "",
        mcc: "",
        city: "",
        line1: "",
        line3: "",
        state: "",
        postal_code: "",
        photo: null
    })

    const [company, setCompany] = useState({
        business_opening_date: "",
        business_name: "",
        business_phone: "",
        business_email: "",
        business_website: "",
        ein: "",
        statement_descriptor: "",
        business_address: {
            city: "",
            line1: "",
            line3: "",
            state: "",
            postal_code: "",
        }
    })

    const [checkout, setCheckout] = useState({
        holder_name: "",
        exp_month: "",
        exp_year: "",
        number: "",
        cvc: "",
        amount: "",
        installment_number: "1",
        description: "Pagamento online com o cartão pela devari pay",
        app_code: APP_ID
    })

    useEffect(() => {
        axios.get(`${API_ZOOP_BASE}/merchant_category_codes?limit=106`, {
            headers: {
                Authorization: AUTH_ZOOP_KEY
            }
        })
        .then(res => {setListMCC(res.data.items)})
        .catch(err => console.log(err))
    }, [])

    const handleAccount = (key, value) => {
        let newAccout;
        if(key === "phone_number" || key === "taxpayer_id" || key === "postal_code") {
            newAccout = {...account, [key]: value.replace(/[^0-9]+/g, "")}
            setAccount(newAccout);
        } else {
            newAccout = {...account, [key]: value}
            setAccount(newAccout);
        }
    }

    const submitAccount = (form) => {
        form.preventDefault();
        
        let valid = validAccount(account);
        if(!valid.status) {
            setAlert(valid.message);
            return ;
        } else { setStep(1) }
    }

    const submitAddress = (form) => {
        form.preventDefault();

        let valid = validAddress(account);
        if(!valid.status) {
            setAlert(valid.message);
            return ;
        }

        if(typeSeller === 'PJ') {
            console.log('PJ')
            setLoading(true)
            axios.post(`${API_ZOOP_URL}/sellers/individuals`, {
                ...account,
                address: {
                    line1: account.line1,
                    line3: account.line3,
                    city: account.city,
                    state: account.state,
                    postal_code: account.postal_code,
                }
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': AUTH_ZOOP_KEY,
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                console.log(res.data);
                const {id} = res.data;
                const formData = new FormData();
                formData.append("file", account.photo);
                formData.append("category", "identificacao")
                formData.append("description", "Seller criado no checkout devari pay")
                //criando documento vendedor
                axios.post(`${API_ZOOP_URL}/sellers/${id}/documents`, formData, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': AUTH_ZOOP_KEY,
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    console.log(res)
                    setStep(3)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setAlert("Não foi possível realizar o cadastro. Verifique suas informações ou entre em contato conosco contato@devari.com.br")
                    setStep(0)
                    axios.delete(`${API_ZOOP_URL}/sellers/${id}`, {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': AUTH_ZOOP_KEY,
                            'Content-Type': 'application/json'
                        }
                    })
                    .then(res => {
                        console.log('deletou', res.data)
                        setLoading(false)
                    })
                    .catch(e => {
                        console.log(e)
                        setLoading(false)
                    })
                })
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setAlert("Não foi possível realizar o cadastro. Verifique suas informações ou entre em contato conosco contato@devari.com.br")
                setStep(0)
            })
        } else {
            setStep(2)
        }
    }

    const handleCompany = (key, value) => {
        let newCompany;
        if(key === "postal_code") {
            let raw_value = value.replace(/[^0-9]+/g, "")
            newCompany = {
                ...company, 
                business_address: {
                    ...company.business_address,
                    [key]: raw_value
                }
            }
            setCompany(newCompany);
        } else if (key === "ein") {
            let raw_value = value.replace(/[^0-9]+/g, "")
            newCompany = {...company, "ein": raw_value}
            setCompany(newCompany);
        } else if(key === "business_phone") {
            let raw_value = value.replace(/[^0-9]+/g, "")
            newCompany = {...company, "business_phone": raw_value}
            setCompany(newCompany);
        } else if(
            key === "line1" || key === "line3" || key === "city" ||
            key === "state" || key === "postal_code"
        ) {
            newCompany = {
                ...company, 
                business_address: {
                    ...company.business_address,
                    [key]: value
                }
            }
            setCompany(newCompany);
        } else {
            newCompany = {...company, [key]: value}
            setCompany(newCompany);
        }
    }

    const submitCompany = (form) => {
        form.preventDefault();
        
        let valid = validCompany(company);
        if(!valid.status) {
            setAlert(valid.message);
            return ;
        }

        setLoading(true)
        const {
            first_name, last_name, email, phone_number,
            taxpayer_id, birthdate, line1, line3, city,
            state, postal_code, mcc
        } = account;
        axios.post(`${API_ZOOP_URL}/sellers/businesses`, {
            owner: {
                first_name, last_name, email, phone_number,
                taxpayer_id, birthdate
            },
            owner_address: {
                line1, line3, city, state, postal_code
            },
            ...company,
            mcc
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': AUTH_ZOOP_KEY,
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            console.log(res.data)
            const {id} = res.data;
            const formData = new FormData();
            formData.append("file", account.photo);
            formData.append("category", "identificacao")
            formData.append("description", "Seller criado no checkout devari pay")
            //criando documento vendedor
            axios.post(`${API_ZOOP_URL}/sellers/${id}/documents`, formData, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': AUTH_ZOOP_KEY,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                console.log(res)
                setStep(3)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setAlert("Não foi possível realizar o cadastro. Verifique suas informações ou entre em contato conosco contato@devari.com.br")
                setStep(0)
                axios.delete(`${API_ZOOP_URL}/sellers/${id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': AUTH_ZOOP_KEY,
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => {
                    console.log('deletou', res.data)
                    setLoading(false)
                })
                .catch(e => {
                    console.log(e)
                    setLoading(false)
                })
            })
        })
        .catch(error => console.log(error))
    }

    const handleCheckout = (key, value) => {
        if(key === "exp_valid") {
            let raw_value = value.replace(/[^0-9]+/g, "")
            if(raw_value.length < 3) {
                let newCheckout = {
                    ...checkout,
                    exp_month: raw_value
                }
                setCheckout(newCheckout)
            } else {
                let newCheckout = {
                    ...checkout,
                    exp_year: raw_value.substring(2)
                }
                setCheckout(newCheckout)
            }
        } else if (key === "number") {
            let newCheckout = {...checkout, [key]: value}
            setCheckout(newCheckout);
            let check = checkCardBrand(value);
            if (check) setBrand(check.name);
            else setBrand("");

        } else {
            let newCheckout = {...checkout, [key]: value}
            setCheckout(newCheckout)
        }
    }

    const submitCheckout = (form) => {
        form.preventDefault();

        let valid = validCheckout(checkout);
        if(!valid.status){
            setAlert(valid.message);
            return ;
        }

        setLoading(true)

        axios.post(`${PAYMENT_URL}`, {
            ...account,
            ...checkout,
            taxpayer: account.taxpayer_id,
            exp_year: "20"+checkout.exp_year,
            amount: checkout.amount*100
        }, {
            headers: {'Authorization': `Token ${APP_TOKEN}`}
        })
        .then(res => {
            //pagamento realizado com sucesso
            setLoading(false);
            setStep(4);
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
            setAlert("Não foi possível realizar o pagamento. Verifique suas informações ou entre em contato conosco contato@devari.com.br")
            //algo errado.
        })

    }

    return (
        <>
        <Alert fade={true}
        id="alert-message"
        color="danger" isOpen={alert.length} toggle={() => setAlert("")}>
        {alert}
        </Alert>
        <SellerAppBar step={step}/>
        <SellerCheckoutForm 
        step={step}
        account={account}
        company={company}
        setType={setTypeSeller}
        listMCC={listMCC}
        loading={loading}
        brand={brand}
        setModal={setModal}
        handleCheckout={handleCheckout}
        handleCompany={handleCompany}
        handleAccount={handleAccount}
        submitAddress={submitAddress}
        submitAccount={submitAccount}
        submitCompany={submitCompany}
        submitCheckout={submitCheckout}
        />
        <Modal isOpen={modal} toggle={()=>setModal(false)}>
            <ModalHeader className="justify-content-center">Código de Segurança</ModalHeader>
            <ModalBody className="text-center">
            <p>Digite o código de segurança (CVV) de 3 ou 4 dígitos presente no verso do cartão.</p>
            <img src={image.CARD} />
            </ModalBody>
            <ModalFooter className="justify-content-center">
            <button className="confirm-button" color="primary" onClick={() => setModal(false)}>Continuar</button>
            </ModalFooter>
        </Modal>
        </>
    )
}

export default SellerCheckout;