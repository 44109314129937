import React from 'react';

import * as image from '../assets';

const appBar = ({step}) => (
    <div id="appbar">
        <div className="app-item app-item-on">
            <div>
                <img alt='circle' src={ step === 0 ? image.CIRCLE : image.CIRCLE_OK}/>
                <span>Criar usuário</span>
            </div>
            <div className="line line-on">
            </div>
        </div>
        <div className={`app-item ${ step === 0 ? 'app-item-off' : 'app-item-on'}`}>
            <div>
                {step === 2 ? 
                <img alt='circle' src={image.CIRCLE_OK} /> :
                <img alt='circle' src={image.CIRCLE} /> }
                <span>Pagamento</span>
            </div>
            <div className={`line ${ step === 0 ? 'line-off' : 'line-on'} `}>
            </div>
        </div>
    </div>
);

export default appBar;